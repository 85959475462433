<template>
  <div class="loginAdmin">
    <div class="ocr">
      <img src="../assets/ocr.png" alt="票证批量识别系统-蓝色互联" />
    </div>
    <div class="title">票证批量识别</div>
    <!-- <div class="hTitle">
      蓝色互联提供算法服务，支持增值税发票、火车票等票据的批量识别，生成Excel文件。
    </div> -->
    <div class="contnet-upload">
      <el-upload
        :file-list="fileList"
        class="upload-demo"
        drag
        action="#"
        multiple
        :before-upload="beforeUpload"
        accept=".jpg ,.png, .jpeg,.tiff,.bmp,.gif,.webp,.pdf,ofd"
        :limit="1000"
        list-type="text"
        :http-request="uploadHttp"
      >
        <el-button type="primary" color="#1B79FF" v-if="fileList.length == 0">
          <img class="sc" src="../assets/sc.png" alt="票证批量识别系统-蓝色互联" />
          上传或拖拽图片到这里</el-button
        >
        <div class="text" v-if="fileList.length == 0">
          <p>1. 适用于项目申报或财务审计行业。</p>
          <p>
            2.
            支持多种类型增值税发票的结构化识别，生成Excel文件，其他多种类型的票证会逐渐开放使用。
          </p>
          <p>3. 单次导入的文件数量不超过1000个，单张 文件不得超过10M大小。</p>
          <p>
            4.
            文件支持jpg、jpeg、png、tiff、bmp、gif、webp、pdf(仅识别第一张)和ofd格式。
          </p>
          <p>5. 可凭交易单号进行再次查询和下载识别结果Excel格式文件。</p>
          <p>
            6.
            系统按识别成功的数量进行计费，对于识别失败的文件，自动原路退还对应的金额。
          </p>
          <p>7. 识别完成后，可凭交易单号通过电子邮件形式申请发票。</p>
          <p>8. 如在使用中遇到问题，请通过邮件lshl@lshl.net联系客服人员。</p>
        </div>
        <div class="fileData" v-if="fileList.length > 0">
          <div class="list" v-for="file in fileList" :key="file.name">
            <div class="left">
              <img src="../assets/file.png" alt="票证批量识别系统-蓝色互联" />
              <p :title="file.name">{{ file.name }}</p>
            </div>
            <div class="right">
              <img
                src="../assets/del.svg"
                @click.stop="removeFile(file)"
                alt="票证批量识别系统-蓝色互联"
              />
            </div>
          </div>
        </div>
      </el-upload>
      <div class="seclect">
        <div class="left" v-if="fileList.length > 0">
          <el-upload
            :file-list="fileList"
            class="upload-demo"
            action="#"
            multiple
            :before-upload="beforeUpload"
            accept=".jpg ,.png, .jpeg,.tiff,.bmp,.gif,.webp,.pdf,ofd"
            :limit="1000"
            list-type="text"
            :http-request="uploadHttp"
          >
            <el-button type="primary">上传图片</el-button>
          </el-upload>
          <el-button type="primary" style="margin-left: 5px" @click="clearFile"
            >清空文件</el-button
          >
        </div>
        <div :class="fileList.length > 0 ? 'right' : 'btnRight'">
          <span class="type" v-if="fileList.length > 0">票证类型</span>
          <el-select
            v-model="valueType"
            placeholder="请选择"
            clearable
            v-if="fileList.length > 0"
            style="width: 229px"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
            <!-- <el-option label="增值税发票识别" value="增值税发票识别" />
            <el-option label="火车票识别" value="火车票识别" /> -->
          </el-select>
          <el-button
            type="primary"
            color="#1B79FF"
            style="margin-left: 4px"
            @click="toAgree"
          >
            <img src="../assets/sb.png" alt="票证批量识别系统-蓝色互联" />
            开始识别</el-button
          >
        </div>
      </div>
      <div class="result-file">
        <div class="file">
          <div @click="toResult">
            通过交易单号下载以往识别结果文件
            <img src="../assets/zf.png" alt="票证批量识别系统-蓝色互联" />
          </div>
          <span>在手机上微信支付通知详情界面可查看交易单号</span>
        </div>
      </div>
      <div class="wxtz">
        <p>如您有其他批量识别的需求，请通过微信联系我们。</p>
        <img src="../../public/zhangxianming.jpeg" alt="票证批量识别系统-蓝色互联" />
      </div>
    </div>
    <div class="footer">
      <span style="margin-right: 30px" @click="open">京ICP备2023007903号 </span
      ><span @click="company">蓝色互联（北京）技术有限公司</span>提供支持
      <span style="margin-left: 20px" @click="toIndex">关于</span>
    </div>
    <AgreeForm ref="AgreeFormRef" />
  </div>
</template>
<script lang="ts" setup>
import { da, fa } from "element-plus/es/locale";
import { ref, onMounted } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
import { ElNotification } from "element-plus";
import axios from "axios";
import AgreeForm from "./agreeForm.vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

const store = useStore();
const router = useRouter();
const valueType = ref("");
const AgreeFormRef = ref();
const fileList = ref([
  // 初始化时可以有已上传文件的列表
]);
const uploadedFileNames = ref([]);
// 上传
const beforeUpload = (file) => {
  let isLt10M = file.size / 1024 / 1024 < 10;
  if (!isLt10M) {
    ElNotification({
      title: "提示",
      message: "上传的文件大小不能超过 10MB!",
      type: "error",
      duration: 3000,
    });
  }
  if (isLt10M) {
    let fileName = file.name;
    if (uploadedFileNames.value.includes(fileName)) {
      // ElMessage({
      //   message: `文件 ${fileName} 已存在，无法重复上传!`,
      //   type: "error",
      // });
      // 移除已上传的同名文件
      // fileList.value = fileList.value.filter((f) => f.name !== fileName);
    } else {
      uploadedFileNames.value.push(fileName);
      fileList.value.push(file);
    }
  }
  console.log(fileList.value, uploadedFileNames.value);
};

const uploadHttp = (data) => {
  console.log(data, "mmmm");
};
const removeFile = (file) => {
  let index = fileList.value.indexOf(file);
  if (index > -1) {
    fileList.value.splice(index, 1);
    uploadedFileNames.value = uploadedFileNames.value.filter(
      (f) => f !== file.name
    );
    console.log(uploadedFileNames.value, " uploadedFileNames.value ");
  }
};
// 清空文件
const clearFile = () => {
  ElMessageBox.confirm("确定要清空所有文件吗?", "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
  })
    .then(() => {
      fileList.value = [];
      uploadedFileNames.value = [];
      ElNotification({
        title: "提示",
        message: "清空成功！",
        type: "success",
        duration: 3000,
      });
    })
    .catch(() => {
      ElNotification({
        title: "提示",
        type: "info",
        message: "取消清空!",
        duration: 3000,
      });
    });
};

// 开始识别
const toAgree = () => {
  if (fileList.value.length == 0) {
    ElNotification({
      title: "提示",
      message: "需要上传文件！",
      type: "error",
      duration: 3000,
    });
  } else {
    if (!valueType.value) {
      ElNotification({
        title: "提示",
        message: "票证类型不能为空！",
        type: "error",
        duration: 3000,
      });
    } else {
      AgreeFormRef.value.showModal(valueType.value, fileList.value);
    }
  }
};
// 票证类型
const handleChange = (value) => {
  valueType.value = value[value.length - 1];
};
// 结果文件
const toResult = () => {
  router.push("/search-result");
};
// 打开京ICP
const open = () => {
  window.open("https://beian.miit.gov.cn/#/Integrated/recordQuery");
};
// 打开公司
const company = () => {
  window.open("http://www.lansehulian.com");
};
// 获取本机ipv4
const fetchPublicIpV4 = () => {
  axios
    .get(config.apiUrlV4 + "/web/query/getMyIp")
    .then((response) => {
      if (response.data.code == 200) {
      }
    })
    .catch((error) => {
      console.error("Error fetching public IP:", error);
    });
};
// 获取本机ipv6
const fetchPublicIpV6 = () => {
  axios
    .get(config.apiUrlV6 + "/web/query/getMyIp")
    .then((response) => {
      if (response.data.code == 200) {
      }
    })
    .catch((error) => {
      console.error("Error fetching public IP:", error);
    });
};
const options = ref([]);
// 票据类型
const getTree = () => {
  axios
    .get(config.apiUrl + "/getTree")
    .then((response) => {
      if (response.data.code == 200) {
        options.value = response.data.data;
      }
    })
    .catch((error) => {
      console.error("Error fetching public IP:", error);
    });
};
// 首页
const toIndex = () => {
  router.push("/");
};
onMounted(() => {
  fetchPublicIpV4(); //获取本机ipv4
  fetchPublicIpV6(); // 获取本机ipv6
  getTree(); // 票据类型
  checkDevice();
});
function checkDevice() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // 判断是否是移动设备正则
  const regex =
    /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i;

  if (regex.test(userAgent)) {
    ElMessageBox.alert("本系统仅支持电脑浏览器访问使用！", "提示", {
      type: "error",
      showConfirmButton: false,
      showClose: false,
      center: true,
    });
  } else {
  }
}
</script>
<style lang="less" scoped>
.loginAdmin {
  height: 100%;
  width: 100%;
  padding-bottom: 30px;
  .title {
    font-size: 30px;
    font-weight: normal;
    line-height: normal;
    text-align: center;
    color: #3d3d3d;
    margin-top: 15px;
  }
  .hTitle {
    font-size: 18px;
    font-weight: normal;
    line-height: normal;
    text-align: center;
    letter-spacing: 0em;

    font-variation-settings: "opsz" auto;
    font-feature-settings: "kern" on;
    color: #000000;
    margin-top: 16px;
  }
  .ocr {
    text-align: center;
    margin-top: 80px;
    img {
      width: 80px;
    }
  }
  .contnet-upload {
    width: 1058px;
    margin: 27px auto;
    .upload-demo {
      .sc {
        width: 18px;
        height: 20px;
        margin-right: 10px;
      }
      /deep/ .el-button--primary {
        width: 229px;
        height: 42px;
        border-radius: 2px;
        margin-top: 30px;
      }
      /deep/ .el-upload-list {
        display: none;
      }
      .text {
        margin-top: 80px;
        padding-left: 272px;
        p {
          font-size: 12px;
          font-weight: 300;
          line-height: normal;
          text-align: left;
          color: #000000;
        }
      }

      /deep/ .el-upload-dragger {
        // background: rgba(219, 240, 255, 0.4);
        background: #fffedd;

        border: 1px solid #1b79ff;
        border-radius: 2px;
        height: 370px;
        padding: 16px 0 16px 16px;
        overflow: auto;
      }
    }
    .fileData {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;
      .list {
        background: rgba(236, 238, 241, 0.5);
        width: 24%;
        display: flex;
        align-items: center;
        margin-bottom: 2px;
        justify-content: space-between;
        margin-right: 10px;
        height: 25px;
        .left {
          display: flex;
          align-items: center;
          img {
            width: 16px;
            height: 16px;
            margin-right: 4px;
          }
          p {
            font-size: 12px;
            color: #000000;

            text-align: left;
            white-space: nowrap; /* 确保文本在一行内显示 */
            overflow: hidden; /* 隐藏溢出的内容 */
            text-overflow: ellipsis; /* 使用省略号表示文本溢出 */
            border-color: pink;
            width: 190px; /* 设置元素宽度，确保文本溢出 */
          }
        }
        .right {
          img {
            width: 20px;
            height: 20px;
            margin-top: 5px;
            cursor: pointer;
          }
        }
      }
    }

    .seclect {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 15px;
      .left {
        display: flex;
        align-items: center;
        /deep/ .el-button--primary {
          width: 92px;
          height: 32px;
          border-radius: 2px;
          background: #ededed;
          margin-top: 0 !important;
          color: #000000;
          border: none;
          font-size: 13px;
        }
        img {
          width: 16px;
          height: 16px;
          margin-right: 6px;
        }
      }
      .right {
        display: flex;
        align-items: center;
        /deep/ .el-button--primary {
          width: 119px;
          height: 32px;
          border-radius: 2px;
          background: #1b79ff;
          margin-top: 0 !important;
          font-size: 13px;
        }
        img {
          width: 18px;
          height: 18px;
          margin-right: 6px;
          margin-bottom: 2px;
        }
        /deep/ .el-input__wrapper {
          border-radius: 2px;
        }
        .type {
          font-size: 13px;
          font-weight: 350;
          line-height: normal;
          color: #000000;
          padding-right: 12px;
        }
        .type::before {
          content: "* ";
          color: red;
        }
      }
      .btnRight {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        position: relative;
        width: 1058px;
        margin-top: 15px;
        /deep/ .el-button--primary {
          width: 119px;
          height: 32px;
          border-radius: 2px;
          margin-top: 0 !important;
          font-size: 13px;
          position: absolute;
          right: 0;
        }
        img {
          width: 18px;
          height: 18px;
          margin-right: 6px;
          margin-bottom: 2px;
        }
        /deep/ .el-input__wrapper {
          border-radius: 2px;
        }
        .type {
          font-size: 13px;
          font-weight: 350;
          line-height: normal;
          color: #000000;
          padding-right: 12px;
        }
      }
    }

    .result-file {
      display: flex;
      margin-top: 70px;
      .file {
        div {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 246px;
          height: 32px;
          border-radius: 2px;
          background: #ffffff;
          box-sizing: border-box;
          border: 1px solid #1b79ff;
          cursor: pointer;
          img {
            width: 14px;
            height: 14px;
            margin-left: 6px;
          }

          font-size: 13px;
          font-weight: 350;

          color: #1b79ff;
        }
        span {
          font-size: 11px;
          font-weight: 300;
          line-height: normal;
          letter-spacing: 0em;
          color: #5d5d5d;
        }
      }
    }
    .wxtz {
      margin-top: 40px;
      p {
        font-size: 12px;
        font-weight: 300;
        line-height: normal;
        letter-spacing: 0em;

        font-variation-settings: "opsz" auto;
        font-feature-settings: "kern" on;
        color: #000000;
      }
      img {
        width: 90px;
        margin-top: 6px;
      }
    }
  }
  .footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 1;
    height: 30px;
    background: #f6f6f6;
    font-size: 13px;
    font-weight: 300;
    line-height: normal;
    text-align: center;
    color: #000000;
    line-height: 30px;
    z-index: 999;
    span {
      cursor: pointer;
    }
  }
}
/deep/ .el-button {
  border-radius: 2px;
}
.el-select-dropdown__item {
  font-size: 13px !important;
  box-sizing: border-box;
  cursor: pointer;
  height: 25px !important;
  line-height: 25px !important;
  overflow: hidden;
  padding: 0 32px 0 20px;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.el-select-dropdown__item.is-hovering {
  background-color: #1b79ff;
  color: #ffffff;
}
// ====================适配屏幕=====================
@media (max-width: 1500px) {
  .loginAdmin {
    height: 100%;
    width: 100%;
    padding-bottom: 30px;
    .title {
      font-size: 20px;
      font-weight: normal;
      line-height: normal;
      text-align: center;
      color: #3d3d3d;
      margin-top: 5px;
    }
    .hTitle {
      font-size: 14px;
      font-weight: normal;
      line-height: normal;
      text-align: center;
      letter-spacing: 0em;
      font-variation-settings: "opsz" auto;
      font-feature-settings: "kern" on;
      color: #000000;
      margin-top: 12px;
    }
    .ocr {
      text-align: center;
      margin-top: 20px !important;
      img {
        width: 60px;
      }
    }
    .contnet-upload {
      width: 1058px;
      margin: 10px auto;
      .upload-demo {
        .sc {
          width: 18px;
          height: 20px;
          margin-right: 10px;
        }
        /deep/ .el-button--primary {
          width: 200px;
          height: 36px;
          border-radius: 2px;
          margin-top: 30px;
        }
        /deep/ .el-upload-list {
          display: none;
        }
        .text {
          margin-top: 50px;
          padding-left: 272px;
          p {
            font-size: 12px;
            font-weight: 300;
            line-height: normal;
            text-align: left;
            color: #000000;
          }
        }

        /deep/ .el-upload-dragger {
          // background: rgba(219, 240, 255, 0.4);
          background: #fffedd;

          border: 1px solid #1b79ff;
          border-radius: 2px;
          height: 300px;
          padding: 16px 0 16px 16px;
          overflow: auto;
        }
      }
      .fileData {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        .list {
          background: rgba(236, 238, 241, 0.5);
          width: 24%;
          display: flex;
          align-items: center;
          margin-bottom: 2px;
          justify-content: space-between;
          margin-right: 10px;
          height: 25px;
          .left {
            display: flex;
            align-items: center;
            img {
              width: 16px;
              height: 16px;
              margin-right: 4px;
            }
            p {
              font-size: 12px;
              color: #000000;

              text-align: left;
              white-space: nowrap; /* 确保文本在一行内显示 */
              overflow: hidden; /* 隐藏溢出的内容 */
              text-overflow: ellipsis; /* 使用省略号表示文本溢出 */
              border-color: pink;
              width: 190px; /* 设置元素宽度，确保文本溢出 */
            }
          }
          .right {
            img {
              width: 20px;
              height: 20px;
              margin-top: 5px;
              cursor: pointer;
            }
          }
        }
      }

      .seclect {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 15px;
        .left {
          display: flex;
          align-items: center;
          /deep/ .el-button--primary {
            width: 92px;
            height: 32px;
            border-radius: 2px;
            background: #ededed;
            margin-top: 0 !important;
            color: #000000;
            border: none;
            font-size: 13px;
          }
          img {
            width: 16px;
            height: 16px;
            margin-right: 6px;
          }
        }
        .right {
          display: flex;
          align-items: center;
          /deep/ .el-button--primary {
            width: 119px;
            height: 32px;
            border-radius: 2px;
            background: #1b79ff;
            margin-top: 0 !important;
            font-size: 13px;
          }
          img {
            width: 18px;
            height: 18px;
            margin-right: 6px;
            margin-bottom: 2px;
          }
          /deep/ .el-input__wrapper {
            border-radius: 2px;
          }
          .type {
            font-size: 13px;
            font-weight: 350;
            line-height: normal;
            color: #000000;
            padding-right: 12px;
          }
          .type::before {
            content: "* ";
            color: red;
          }
        }
        .btnRight {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          position: relative;
          width: 1058px;
          margin-top: 15px;
          /deep/ .el-button--primary {
            width: 119px;
            height: 32px;
            border-radius: 2px;
            margin-top: 0 !important;
            font-size: 13px;
            position: absolute;
            right: 0;
          }
          img {
            width: 18px;
            height: 18px;
            margin-right: 6px;
            margin-bottom: 2px;
          }
          /deep/ .el-input__wrapper {
            border-radius: 2px;
          }
          .type {
            font-size: 13px;
            font-weight: 350;
            line-height: normal;
            color: #000000;
            padding-right: 12px;
          }
        }
      }

      .result-file {
        display: flex;
        margin-top: 20px;
        .file {
          div {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 246px;
            height: 32px;
            border-radius: 2px;
            background: #ffffff;
            box-sizing: border-box;
            border: 1px solid #1b79ff;
            cursor: pointer;
            img {
              width: 14px;
              height: 14px;
              margin-left: 6px;
            }

            font-size: 13px;
            font-weight: 350;

            color: #1b79ff;
          }
          span {
            font-size: 11px;
            font-weight: 300;
            line-height: normal;
            letter-spacing: 0em;
            color: #5d5d5d;
          }
        }
      }
      .wxtz {
        margin-top: 40px;
        p {
          font-size: 12px;
          font-weight: 300;
          line-height: normal;
          letter-spacing: 0em;

          font-variation-settings: "opsz" auto;
          font-feature-settings: "kern" on;
          color: #000000;
        }
        img {
          width: 90px;
          margin-top: 6px;
        }
      }
    }
  }
}
@media (max-width: 1400px) {
  .loginAdmin {
    height: 100%;
    width: 100%;
    padding-bottom: 30px;
    .title {
      font-size: 18px;
      font-weight: normal;
      line-height: normal;
      text-align: center;
      color: #3d3d3d;
      margin-top: 0px;
    }
    .hTitle {
      font-size: 12px;
      font-weight: normal;
      line-height: normal;
      text-align: center;
      letter-spacing: 0em;
      font-variation-settings: "opsz" auto;
      font-feature-settings: "kern" on;
      color: #000000;
      margin-top: 6px;
    }
    .ocr {
      text-align: center;
      margin-top: 10px !important;
      img {
        width: 50px;
      }
    }
    .contnet-upload {
      width: 1058px;
      margin: 10px auto;
      .upload-demo {
        .sc {
          width: 18px;
          height: 20px;
          margin-right: 10px;
        }
        /deep/ .el-button--primary {
          width: 200px;
          height: 36px;
          border-radius: 2px;
          margin-top: 30px;
        }
        /deep/ .el-upload-list {
          display: none;
        }
        .text {
          margin-top: 20px;
          padding-left: 272px;
          p {
            font-size: 12px;
            font-weight: 300;
            line-height: normal;
            text-align: left;
            color: #000000;
          }
        }

        /deep/ .el-upload-dragger {
          // background: rgba(219, 240, 255, 0.4);
          background: #fffedd;

          border: 1px solid #1b79ff;
          border-radius: 2px;
          height: 250px;
          padding: 16px 0 16px 16px;
          overflow: auto;
        }
      }
      .fileData {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        .list {
          background: rgba(236, 238, 241, 0.5);
          width: 24%;
          display: flex;
          align-items: center;
          margin-bottom: 2px;
          justify-content: space-between;
          margin-right: 10px;
          height: 25px;
          .left {
            display: flex;
            align-items: center;
            img {
              width: 16px;
              height: 16px;
              margin-right: 4px;
            }
            p {
              font-size: 12px;
              color: #000000;

              text-align: left;
              white-space: nowrap; /* 确保文本在一行内显示 */
              overflow: hidden; /* 隐藏溢出的内容 */
              text-overflow: ellipsis; /* 使用省略号表示文本溢出 */
              border-color: pink;
              width: 190px; /* 设置元素宽度，确保文本溢出 */
            }
          }
          .right {
            img {
              width: 20px;
              height: 20px;
              margin-top: 5px;
              cursor: pointer;
            }
          }
        }
      }

      .seclect {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 10px;
        .left {
          display: flex;
          align-items: center;
          /deep/ .el-button--primary {
            width: 92px;
            height: 32px;
            border-radius: 2px;
            background: #ededed;
            margin-top: 0 !important;
            color: #000000;
            border: none;
            font-size: 13px;
          }
          img {
            width: 16px;
            height: 16px;
            margin-right: 6px;
          }
        }
        .right {
          display: flex;
          align-items: center;
          /deep/ .el-button--primary {
            width: 119px;
            height: 32px;
            border-radius: 2px;
            background: #1b79ff;
            margin-top: 0 !important;
            font-size: 13px;
          }
          img {
            width: 18px;
            height: 18px;
            margin-right: 6px;
            margin-bottom: 2px;
          }
          /deep/ .el-input__wrapper {
            border-radius: 2px;
          }
          .type {
            font-size: 13px;
            font-weight: 350;
            line-height: normal;
            color: #000000;
            padding-right: 12px;
          }
          .type::before {
            content: "* ";
            color: red;
          }
        }
        .btnRight {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          position: relative;
          width: 1058px;
          margin-top: 15px;
          /deep/ .el-button--primary {
            width: 119px;
            height: 32px;
            border-radius: 2px;
            margin-top: 0 !important;
            font-size: 13px;
            position: absolute;
            right: 0;
          }
          img {
            width: 18px;
            height: 18px;
            margin-right: 6px;
            margin-bottom: 2px;
          }
          /deep/ .el-input__wrapper {
            border-radius: 2px;
          }
          .type {
            font-size: 13px;
            font-weight: 350;
            line-height: normal;
            color: #000000;
            padding-right: 12px;
          }
        }
      }

      .result-file {
        display: flex;
        margin-top: 10px;
        .file {
          div {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 246px;
            height: 32px;
            border-radius: 2px;
            background: #ffffff;
            box-sizing: border-box;
            border: 1px solid #1b79ff;
            cursor: pointer;
            img {
              width: 14px;
              height: 14px;
              margin-left: 6px;
            }

            font-size: 13px;
            font-weight: 350;

            color: #1b79ff;
          }
          span {
            font-size: 11px;
            font-weight: 300;
            line-height: normal;
            letter-spacing: 0em;
            color: #5d5d5d;
          }
        }
      }
      .wxtz {
        margin-top: 20px;
        p {
          font-size: 12px;
          font-weight: 300;
          line-height: normal;
          letter-spacing: 0em;

          font-variation-settings: "opsz" auto;
          font-feature-settings: "kern" on;
          color: #000000;
        }
        img {
          width: 90px;
          margin-top: 6px;
        }
      }
    }
  }
}
@media (max-width: 1300px) {
  .loginAdmin {
    height: 100%;
    width: 100%;
    padding-bottom: 30px;
    .title {
      font-size: 26px;
      font-weight: normal;
      line-height: normal;
      text-align: center;
      color: #3d3d3d;
      margin-top: 10px;
    }
    .hTitle {
      font-size: 16px;
      font-weight: normal;
      line-height: normal;
      text-align: center;
      letter-spacing: 0em;
      font-variation-settings: "opsz" auto;
      font-feature-settings: "kern" on;
      color: #000000;
      margin-top: 16px;
    }
    .ocr {
      text-align: center;
      margin-top: 10px !important;
      img {
        width: 60px;
      }
    }
    .contnet-upload {
      width: 1058px;
      margin: 20px auto;
      .upload-demo {
        .sc {
          width: 18px;
          height: 20px;
          margin-right: 10px;
        }
        /deep/ .el-button--primary {
          width: 240px;
          height: 43px;
          border-radius: 2px;
          margin-top: 30px;
        }
        /deep/ .el-upload-list {
          display: none;
        }
        .text {
          margin-top: 70px;
          padding-left: 272px;
          p {
            font-size: 12px;
            font-weight: 300;
            line-height: normal;
            text-align: left;
            color: #000000;
          }
        }

        /deep/ .el-upload-dragger {
          // background: rgba(219, 240, 255, 0.4);
          background: #fffedd;

          border: 1px solid #1b79ff;
          border-radius: 2px;
          height: 340px;
          padding: 16px 0 16px 16px;
          overflow: auto;
        }
      }
      .fileData {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        .list {
          background: rgba(236, 238, 241, 0.5);
          width: 24%;
          display: flex;
          align-items: center;
          margin-bottom: 2px;
          justify-content: space-between;
          margin-right: 10px;
          height: 25px;
          .left {
            display: flex;
            align-items: center;
            img {
              width: 16px;
              height: 16px;
              margin-right: 4px;
            }
            p {
              font-size: 12px;
              color: #000000;

              text-align: left;
              white-space: nowrap; /* 确保文本在一行内显示 */
              overflow: hidden; /* 隐藏溢出的内容 */
              text-overflow: ellipsis; /* 使用省略号表示文本溢出 */
              border-color: pink;
              width: 190px; /* 设置元素宽度，确保文本溢出 */
            }
          }
          .right {
            img {
              width: 20px;
              height: 20px;
              margin-top: 5px;
              cursor: pointer;
            }
          }
        }
      }

      .seclect {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 15px;
        .left {
          display: flex;
          align-items: center;
          /deep/ .el-button--primary {
            width: 92px;
            height: 32px;
            border-radius: 2px;
            background: #ededed;
            margin-top: 0 !important;
            color: #000000;
            border: none;
            font-size: 13px;
          }
          img {
            width: 16px;
            height: 16px;
            margin-right: 6px;
          }
        }
        .right {
          display: flex;
          align-items: center;
          /deep/ .el-button--primary {
            width: 119px;
            height: 32px;
            border-radius: 2px;
            background: #1b79ff;
            margin-top: 0 !important;
            font-size: 13px;
          }
          img {
            width: 18px;
            height: 18px;
            margin-right: 6px;
            margin-bottom: 2px;
          }
          /deep/ .el-input__wrapper {
            border-radius: 2px;
          }
          .type {
            font-size: 13px;
            font-weight: 350;
            line-height: normal;
            color: #000000;
            padding-right: 12px;
          }
          .type::before {
            content: "* ";
            color: red;
          }
        }
        .btnRight {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          position: relative;
          width: 1058px;
          margin-top: 15px;
          /deep/ .el-button--primary {
            width: 119px;
            height: 32px;
            border-radius: 2px;
            margin-top: 0 !important;
            font-size: 13px;
            position: absolute;
            right: 0;
          }
          img {
            width: 18px;
            height: 18px;
            margin-right: 6px;
            margin-bottom: 2px;
          }
          /deep/ .el-input__wrapper {
            border-radius: 2px;
          }
          .type {
            font-size: 13px;
            font-weight: 350;
            line-height: normal;
            color: #000000;
            padding-right: 12px;
          }
        }
      }

      .result-file {
        display: flex;
        margin-top: 20px;
        .file {
          div {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 246px;
            height: 32px;
            border-radius: 2px;
            background: #ffffff;
            box-sizing: border-box;
            border: 1px solid #1b79ff;
            cursor: pointer;
            img {
              width: 14px;
              height: 14px;
              margin-left: 6px;
            }

            font-size: 13px;
            font-weight: 350;

            color: #1b79ff;
          }
          span {
            font-size: 11px;
            font-weight: 300;
            line-height: normal;
            letter-spacing: 0em;
            color: #5d5d5d;
          }
        }
      }
      .wxtz {
        margin-top: 40px;
        p {
          font-size: 12px;
          font-weight: 300;
          line-height: normal;
          letter-spacing: 0em;

          font-variation-settings: "opsz" auto;
          font-feature-settings: "kern" on;
          color: #000000;
        }
        img {
          width: 90px;
          margin-top: 6px;
        }
      }
    }
  }
}
</style>
<style>
.el-select__wrapper {
  font-size: 13px !important;
}
</style>
