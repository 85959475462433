<template>
  <div class="agreeForm">
    <el-drawer
      size="50%"
      v-model="dialogVisible"
      :close-on-click-modal="false"
      :show-close="false"
      :before-close="handleClose"
    >
      <!-- 同意付款 -->
      <el-skeleton
        :rows="3"
        v-if="loading"
        style="width: 650px; margin: 50px auto"
      />
      <div class="content" v-if="showType == 0 && !loading">
        <div class="title">结算</div>
        <p class="p1">
          本次上传{{ fileList.length }}个文件，单价为{{
            formData.unitPrice ? formData.unitPrice : 0
          }}元，共需支付算法服务费<span>{{
            formData.totalPrice ? formData.totalPrice : 0
          }}</span
          >元。
        </p>
        <p class="p2">
          （如有上传不成功或识别失败的情况，系统会自动退款对应金额。）
        </p>
        <div class="btn">
          <el-button type="primary" @click="toPayment">
            同意并使用微信付款
          </el-button>
          <el-button class="closeBtn" @click="close">取消</el-button>
        </div>

        <div class="code" v-show="showMa">
          <div class="money">
            ¥{{ formData.totalPrice ? formData.totalPrice : 0 }}
          </div>
          <div id="qrcode" role="img" aria-hidden="true"></div>
        </div>
      </div>
      <!-- 文件上传中 -->
      <div class="contentProgress" v-if="showType == 1">
        <div class="title">文件上传中</div>
        <p class="p1">
          正在将本地文件提交到算法系统，调整文件需要一些时间，请勿关闭页面。
        </p>
        <div class="progressP" style="padding-top: 70px">
          <p>
            {{ upLoadprogress == 100 ? "上传完成" : "正在上传中" }}
            {{ upLoadprogress + "%" }}
          </p>
          <progress
            class="progress"
            style="width: 90%"
            :value="upLoadprogress"
            max="100"
            color="#1B79FF;"
          ></progress>
        </div>
        <div class="progressP" style="padding-top: 30px">
          <p>
            {{
              fileList.length == scData ? "已经完成" : "正在对文件进行预处理"
            }}
            {{ scData ? scData : 0 }} / {{ fileList.length }}
          </p>
          <progress
            class="progress"
            style="width: 90%"
            :value="scData"
            :max="fileList.length"
            color="#1B79FF;"
          ></progress>
        </div>
        <!-- <el-button
          v-if="scData == fileList.length"
          type="primary"
          color="#1B79FF"
          class="beginBtn"
          @click="startFile"
        >
          开始批量识别文件
        </el-button> -->
      </div>
    </el-drawer>
  </div>
</template>
<script lang="ts" setup>
import { fa } from "element-plus/es/locale";
import { ref, onMounted, computed } from "vue";
import { ElMessage, ElIcon } from "element-plus";
import { ElNotification } from "element-plus";
import { useRouter } from "vue-router";
import QRCode from "qrcodejs2";
import axios from "axios";
const router = useRouter();
const dialogVisible = ref(false);
const showType = ref(0);
const valueType = ref();
const formData = ref({});
const fileList = ref([]);
const showMa = ref(false);
const timerId = ref(null);
const timerId2 = ref(null);
const timerId3 = ref(null);
const timerId4 = ref(null);
const scData = ref("");
const loading = ref(false);
const sbDetail = ref([]);
const screenResolution = ref("unknown");
const upLoadprogress = ref(0);
onMounted(() => {});
const showModal = (type, list) => {
  valueType.value = type;
  fileList.value = list;
  console.log(list, "list");
  showMa.value = false;
  getQrCode(type); //获取二维码
  dialogVisible.value = true;
};
//获取二维码
const getQrCode = (type) => {
  loading.value = true;
  axios
    .post(config.apiUrl + "/data/data/getQrCode", {
      size: fileList.value.length,
      type: type,
    })
    .then((response) => {
      if (response.data.code == 200) {
        loading.value = false;
        formData.value = response.data.data;
      } else {
        loading.value = false;
        ElNotification({
          title: "提示",
          message: response.data.msg,
          type: "error",
          duration: 3000,
        });
      }
    })
    .catch((error) => {
      console.error("Error", error);
    });
};

// 查询是否付款
const queryPayOrder = () => {
  axios
    .get(config.apiUrl + "/data/data/queryPayOrder", {
      params: {
        tradeNo: formData.value.tradeNo,
        ossId: formData.value.ossId,
      },
    })
    .then((response) => {
      if (response.data.code == 200) {
        if (timerId.value) {
          clearInterval(timerId.value); //清除查询是否付款
        }
        // 上传文件
        const formUrl = new FormData();
        fileList.value.map((item) => {
          formUrl.append("file", item);
        });
        setTimeout(() => {
          uploadData(formUrl); // 上传接口
        }, 2000);
        showType.value = 1;
      }
    })
    .catch((error) => {
      console.error("Error", error);
    });
};
// 微信支付
const toPayment = () => {
  showMa.value = true;
  if (timerId.value) {
    clearInterval(timerId.value); //清除查询是否付款
  }
  document.getElementById("qrcode").innerHTML = "";
  let qrcodeCreater = new QRCode(document.getElementById("qrcode"), {
    text: formData.value.url,
    width: 220,
    height: 220,
    colorDark: "#000000",
    colorLight: "#ffffff",
    correctLevel: QRCode.CorrectLevel.H,
  });
  qrcodeCreater._el.title = ""; //隐藏title
  //查询是否付款
  timerId.value = setInterval(() => {
    queryPayOrder();
  }, 1000);
};
// 上传接口
const uploadData = (formUrl) => {
  timerId3.value = setInterval(() => {
    getIfUpload();
  }, 1000);
  axios
    .post(
      config.apiUrl +
        "/data/data/uploadBatch?tradeNo=" +
        formData.value.tradeNo,
      formUrl,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          if (progressEvent.lengthComputable) {
            upLoadprogress.value = Math.round(
              (progressEvent.loaded / progressEvent.total) * 100
            );
            if (upLoadprogress.value == 100) {
              getUploadProgress(); // 获取上传进度
            }
          }
          // console.log(progressEvent, "progressEvent", upLoadprogress.value);
        },
        timeout: 60000, // 设置超时时间为60000毫秒（60秒）
      }
    )
    .then((response) => {
      if (response.data.code == 200) {
      }
    })
    .catch((error) => {
      console.error("Error fetching public IP:", error);
    });
};
const getIfUpload = () => {
  axios
    .get(
      config.apiUrl + "/data/data/getIfUpload?tradeNo=" + formData.value.tradeNo
    )
    .then((response) => {
      if (response.data.code == 200) {
        if (upLoadprogress.value != 100) {
          upLoadprogress.value =100
          getUploadProgress(); // 获取上传进度
        }
        if (timerId3.value) {
          clearInterval(timerId3.value); //清除查询是否付款
        }
      }
    })
    .catch((error) => {
      console.error("Error fetching public IP:", error);
    });
};
// 获取上传进度
const getUploadProgress = () => {
  axios
    .get(
      config.apiUrl +
        "/data/data/getUploadProgress?tradeNo=" +
        formData.value.tradeNo,
      {}
    )
    .then((response) => {
      if (response.data.code == 200) {
        scData.value = response.data.data.progress;
        if (!response.data.data.isComplete) {
          setTimeout(() => {
            getUploadProgress(); // 获取上传进度
          }, 2000);
        }
        if (response.data.data.isComplete) {
          setTimeout(() => {
            startRecognition(); // 开始识别
          }, 2000);
        }
      }
    })
    .catch((error) => {
      console.error("Error", error);
    });
};
// 开始识别文件
// const startFile = () => {
//   startRecognition(); // 开始识别
// };
// 取消
const close = () => {
  if (timerId.value) {
    clearInterval(timerId.value); //清除查询是否付款
  }
  dialogVisible.value = false;
};
// 开始识别
const startRecognition = () => {
  axios
    .post(config.apiUrl + "/data/data/startRecognition", {
      tradeNo: formData.value.tradeNo,
    })
    .then((response) => {
      if (response.data.code == 200) {
        router.push({
          path: "/result",
          query: {
            tradeNo: formData.value.tradeNo,
            length: fileList.value.length,
          },
        });
        sessionStorage.removeItem("rIndex", 0);
      }
    })
    .catch((error) => {
      console.error("Error", error);
    });
};

// 下载文件
const uploadFile = () => {
  router.push({
    path: "/result",
    query: {
      orderWx: orderWx.value,
    },
  });
};
defineExpose({ showModal });
</script>
<style>
.progress {
  height: 10px;
  border-radius: 10px !important;
}

progress::-webkit-progress-bar {
  border-radius: 10px !important;
  background: #cfcece !important;
}

.progress::-webkit-progress-value {
  border-radius: 10px !important;
  background: #1b79ff !important;
}
</style>

<style lang="less" scoped>
.content {
  margin: 0 auto;
  text-align: center;
  .title {
    font-size: 30px;
    font-weight: normal;
    line-height: normal;
    text-align: center;
    letter-spacing: 0em;

    font-variation-settings: "opsz" auto;
    font-feature-settings: "kern" on;
    color: #3d3d3d;
    margin-bottom: 82px;
    margin-top: 50px;
  }
  .p1 {
    font-size: 20px;
    font-weight: 350;
    line-height: normal;
    letter-spacing: 0em;
    color: #3d3d3d;
    span {
      color: #1b79ff;
    }
  }
  .p2 {
    font-size: 13px;
    font-weight: 350;
    line-height: normal;
    text-align: center;
    letter-spacing: 0em;
    margin-top: 20px;
  }
  .btn {
    margin-top: 48px;

    /deep/.el-button--primary {
      width: 167px;
      height: 32px;
      border-radius: 2px;
      opacity: 1;
      background: #1b79ff;
      box-sizing: border-box;
      border: 0.5px solid #1b79ff;
      font-size: 14px;
      line-height: 32px;
    }
    /deep/.closeBtn {
      width: 89px;
      height: 32px;
      border-radius: 2px;
      opacity: 1;
      background: #ffffff;
      color: #000000;
      font-size: 14px;
      box-sizing: border-box;
      border: 0.5px solid #1b79ff;
      line-height: 32px;
    }
  }
  .code {
    margin-top: 102px;
    .money {
      font-size: 36px;
      font-weight: 700;
      line-height: normal;
      text-align: center;
      color: #000000;
    }
    #qrcode {
      width: 220px;
      height: 220px;
      text-align: center;
      margin: 10px auto;
    }
  }
}
.contentProgress {
  margin: 0 auto;
  text-align: center;
  .title {
    font-size: 30px;
    font-weight: normal;
    line-height: normal;
    text-align: center;
    letter-spacing: 0em;

    font-variation-settings: "opsz" auto;
    font-feature-settings: "kern" on;
    color: #3d3d3d;
    margin-bottom: 82px;
    margin-top: 50px;
  }
  .p1 {
    font-size: 18px;
    font-weight: 350;
    line-height: normal;
    text-align: center;
    letter-spacing: 0em;
    font-variation-settings: "opsz" auto;
    font-feature-settings: "kern" on;
    color: #000000;
  }
  .progressP {
    p {
      font-size: 14px;
      font-weight: 900;
      line-height: normal;
      text-align: center;
      letter-spacing: 0em;

      font-variation-settings: "opsz" auto;
      font-feature-settings: "kern" on;
      color: #3d3d3d;
      margin-bottom: 5px;
    }
    margin: 0 auto;
    width: 649px;
  }
  .beginBtn {
    width: 155px;
    height: 32px;
    border-radius: 2px;
    opacity: 1;

    box-sizing: border-box;

    font-size: 14px;
    font-weight: 350;
    line-height: normal;
    text-align: center;
    letter-spacing: 0em;

    font-variation-settings: "opsz" auto;
    font-feature-settings: "kern" on;
    color: #ffffff;
    margin-top: 90px;
  }
}
</style>