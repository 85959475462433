<template>
  <div class="index">
    <div class="top">
      <img src="../assets/logo.svg" alt="票证批量识别系统-蓝色互联" />
    </div>
    <div class="title">
      蓝色互联，提供高精准的发票批量识别、以及其他票证识别OCR算法技术。
    </div>
    <div class="content">
      <div class="left">
        <img src="../../public/indexImg.jpg" alt="票证批量识别系统-蓝色互联" />
      </div>
      <div class="right">
        <div>
          <p>适用于项目申报或财务审计行业</p>
          <p>无需要开发对接即可直接使用</p>
          <p>支持多种类型增值税发票的结构化识别生成Excel文件</p>
          <p>支持jpg、jpeg、png、tiff、bmp、gif、webp、</p>
          <p>pdf（默认识别第一张）和ofd格式</p>
          <p>支持再次查询和下载识别结果Excel格式文件</p>
          <p>对于识别失败的文件可自动退款</p>
          <p>可提供算法基础接口</p>
        </div>
        <el-button
          type="primary"
          @click="toStart"
          color="#1B79FF"
        >
          现在开始使用发票批量识别
        </el-button>
      </div>
    </div>
    <div class="footer">
      <span style="margin-right: 30px" @click="open">京ICP备2023007903号 </span
      ><span @click="company">蓝色互联（北京）技术有限公司</span>提供支持
      <span style="margin-left: 20px">关于</span>
    </div>
  </div>
</template>
  
  <script lang="ts" setup>
import { fa } from "element-plus/es/locale";
import { ref, onMounted, reactive } from "vue";
import { useRouter } from "vue-router";
import type { ComponentSize, FormInstance, FormRules } from "element-plus";
import { ElMessage, ElMessageBox } from "element-plus";
import { ElNotification } from "element-plus";
import axios from "axios";
const router = useRouter();
// 打开公司
const company = () => {
  window.open("http://www.lansehulian.com");
};
// 打开京ICP
const open = () => {
  window.open("https://beian.miit.gov.cn/#/Integrated/recordQuery");
};
// 首页
const toStart = () => {
  router.push("/start");
};
// 获取本机ipv4
const fetchPublicIpV4 = () => {
  axios
    .get(config.apiUrlV4 + "/web/query/getMyIp")
    .then((response) => {
      if (response.data.code == 200) {
      }
    })
    .catch((error) => {
      console.error("Error fetching public IP:", error);
    });
};
// 获取本机ipv6
const fetchPublicIpV6 = () => {
  axios
    .get(config.apiUrlV6 + "/web/query/getMyIp")
    .then((response) => {
      if (response.data.code == 200) {
      }
    })
    .catch((error) => {
      console.error("Error fetching public IP:", error);
    });
};
onMounted(() => {
  fetchPublicIpV4(); //获取本机ipv4
  fetchPublicIpV6(); // 获取本机ipv6
});
</script>
  <style lang="less" scoped>
.index {
  padding: 38px 46px;
  .top {
    img {
      width: 140px;
    }
    margin-bottom: 68px;
  }
  .title {
    font-size: 30px;
    font-weight: normal;
    line-height: normal;
    text-align: center;
    letter-spacing: 0em;
    color: #000000;
  }
  .content {
    padding-top: 81px;
    display: flex;
    justify-content: center;

    .left {
      img {
        width: 681px;
        height: 454px;
        border-radius: 10px;
      }
    }
    .right {
      margin: 28px 0 0 73px;
      div {
        margin-bottom: 75px;
        p {
          font-size: 22px;
          font-weight: 300;
          line-height: normal;
          letter-spacing: 0em;
          color: #000000;
        }
      }
      /deep/.el-button--primary {
        width: 209px;
        height: 36px;
        border-radius: 2px;
        opacity: 1;

        box-sizing: border-box;

        font-size: 14px;
        line-height: 36px;
      }
    }
  }
}
@media (max-width: 1400px) {
  .index {
    padding: 38px 46px;
    .top {
      img {
        width: 140px;
      }
      margin-bottom: 30px;
    }
    .title {
      font-size: 30px;
      font-weight: normal;
      line-height: normal;
      text-align: center;
      letter-spacing: 0em;
      color: #000000;
    }
    .content {
      padding-top: 41px;
      display: flex;
      justify-content: center;

      .left {
        img {
          width: 481px;
          height: 354px;
          border-radius: 10px;
        }
      }
      .right {
        margin: 28px 0 0 73px;
        div {
          margin-bottom: 75px;
          p {
            font-size: 22px;
            font-weight: 300;
            line-height: normal;
            letter-spacing: 0em;
            color: #000000;
          }
        }
        /deep/.el-button--primary {
          width: 209px;
          height: 36px;
          border-radius: 2px;
          opacity: 1;

          box-sizing: border-box;

          font-size: 14px;
          line-height: 36px;
        }
      }
    }
  }
}
@media (max-width: 540px) {
  .index {
    padding: 16px 16px 40px 16px;
    .top {
      img {
        width: 140px;
      }
      margin-bottom: 18px;
    }
    .title {
      font-size: 20px;
      font-weight: normal;
      line-height: normal;
      text-align: left;
      letter-spacing: 0em;
      color: #000000;
    }
    .content {
      padding-top: 25px;
      display: inline-block;
      margin-left: 30px;

      .left {
        img {
          width: 280px;
          height: 254px;
          border-radius: 10px;
        }
      }
      .right {
        margin: 20px 0 0 0px;
        div {
          margin-bottom: 55px;
          p {
            font-size: 14px;
            font-weight: 300;
            line-height: normal;
            letter-spacing: 0em;
            color: #000000;
          }
        }
        /deep/.el-button--primary {
          width: 209px;
          height: 36px;
          border-radius: 2px;
          opacity: 1;

          box-sizing: border-box;
          font-size: 14px;
          line-height: 36px;
        }
      }
    }
  }
  .footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 1;
    height: 30px;
    background: #f6f6f6;
    font-size: 10px !important;
    font-weight: 300;
    line-height: normal;
    text-align: center;
    color: #000000;
    line-height: 30px;
    span {
      cursor: pointer;
    }
  }
}
@media (max-width: 350px) {
}
.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 1;
  height: 30px;
  background: #f6f6f6;
  font-size: 13px;
  font-weight: 300;
  line-height: normal;
  text-align: center;
  color: #000000;
  line-height: 30px;
  span {
    cursor: pointer;
  }
}
</style>