import { createRouter, createWebHashHistory, RouteRecordRaw, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import Index from "../views/index.vue";
const routes: Array<RouteRecordRaw> = [
    {
    path: "/",
    name: "index",
    component: Index,
  },
  {
    path: "/start",
    name: "start",
    component: HomeView,
  },

  {
    path: "/result",
    name: "result",
    component: () =>
      import(/* webpackChunkName: "result" */ "../views/result.vue"),
  },
  {
    path: "/search-result",
    name: "search-result",
    component: () =>
      import(/* webpackChunkName: "search-result" */ "../views/search-result.vue"),
  },
  
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
